<template>
  <v-form class="multi-col-validation mt-1" v-if="ytd!==null">
    <!-- gross, regular pay, regular pay non tax, overtime -->
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.gross)"
          outlined
          dense
          placeholder="Gross"
          label="Gross"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.regular_pay)"
          outlined
          dense
          placeholder="Regular Pay"
          label="Regular Pay"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.regular_pay_non_tax)"
          outlined
          dense
          placeholder="Regular Pay Non Tax"
          label="Regular Pay Non Tax"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.overtime)"
          outlined
          dense
          placeholder="Overtime"
          label="Overtime"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- night diff, holiday, wtax, sss -->
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.night_diff)"
          outlined
          dense
          placeholder="Night Diff"
          label="Night Diff"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.holiday)"
          outlined
          dense
          placeholder="Holiday"
          label="Holiday"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format((ytd.wtax + ytd.wtax_special))"
          outlined
          dense
          placeholder="WTax"
          label="WTax"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.sss)"
          outlined
          dense
          placeholder="SSS"
          label="SSS"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- pagibig, philhealth, cola, discola -->
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.pagibig)"
          outlined
          dense
          placeholder="PagIBIG"
          label="PagIBIG"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.philhealth)"
          outlined
          dense
          placeholder="Philhealth"
          label="Philhealth"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.cola)"
          outlined
          dense
          placeholder="COLA"
          label="COLA"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.discola)"
          outlined
          dense
          placeholder="DisCOLA"
          label="DisCOLA"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- 13 month accumulated,  13 month released, allowance, bonus-->
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd._13month)"
          outlined
          dense
          placeholder="13 Month Accumulated"
          label="13 Month Accumulated"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd._13MonthReleased)"
          outlined
          dense
          placeholder="13 Month Released"
          label="13 Month Released"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.allowance)"
          outlined
          dense
          placeholder="Allowance"
          label="Allowance"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.bonus)"
          outlined
          dense
          placeholder="Bonus"
          label="Bonus"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- union dues, non taxables, meal sub, others -->
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.union_dues)"
          outlined
          dense
          placeholder="Union Dues"
          label="Union Dues"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.nonTaxable)"
          outlined
          dense
          placeholder="Non Taxable"
          label="Non Taxable"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.meal_subsidy)"
          outlined
          dense
          placeholder="Meal Subsidy"
          label="Meal Subsidy"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(ytd.others)"
          outlined
          dense
          placeholder="Others"
          label="Others"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: ['data'],
  setup(props) {
    const ytd = ref(null)

    watch(() => props.data, val => {
      ytd.value = val.ytd
    })

    return {
      ytd,
    }
  },
}
</script>
