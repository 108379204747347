<template>
  <div>
    <v-card :loading="employeeRecord===null && routeParameter!==undefined">
      <v-card-title>Personal Information</v-card-title>
      <v-card-text>
        <personal-info @emitData="getData"></personal-info>
      </v-card-text>
    </v-card>

    <v-card class="mt-2" :loading="employeeRecord===null && routeParameter!==undefined">
      <v-card-title>General Information</v-card-title>
      <v-card-text>
        <general-info :data="employeeRecord"></general-info>
      </v-card-text>
    </v-card>

    <v-card class="mt-2" :loading="employeeRecord===null && routeParameter!==undefined">
      <v-card-title>Year To Date</v-card-title>
      <v-card-text>
        <ytd :data="employeeRecord"></ytd>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import fnx from '@/functions/fn'
import { useRouter } from '@/utils'
import personalInfo from './personalInfo.vue'
import generalInfo from './generalInfo.vue'
import ytd from './ytd.vue'

export default {
  metaInfo: {
    title: '201',
  },

  components: {
    personalInfo,
    generalInfo,
    ytd,
  },

  setup() {
    const employeeRecord = ref(null)
    const router = useRouter()
    const routeParameter = router.route.value.params.data !== undefined ? fnx.base64_decode(router.route.value.params.data) : undefined
    const getData = data => {
      employeeRecord.value = data.value
    }

    return {
      routeParameter,
      employeeRecord,
      getData,
    }
  },
}
</script>
