<template>
  <v-form class="multi-col-validation mt-1" v-if="employeeRecord!==null">
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          :value="employeeRecord.division.title"
          label="Division"
          outlined
          dense
          placeholder="Division"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-if="employeeRecord.department !== null">
        <v-text-field
          :value="employeeRecord.department.title"
          label="Department"
          outlined
          dense
          placeholder="Department"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" v-else>
        <v-text-field
          :value="employeeRecord.outlet.title"
          label="Outlet"
          outlined
          dense
          placeholder="Outlet"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- position, posisition level, employment status, status -->
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          :value="employeeRecord.position.title"
          label="Position"
          outlined
          dense
          placeholder="Position"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="employeeRecord.position_level.title"
          label="Position Level"
          outlined
          dense
          placeholder="Position Level"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="employeeRecord.employment_status"
          label="Employment Status"
          outlined
          dense
          placeholder="Employment Status"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="employeeRecord.status"
          label="Status"
          outlined
          dense
          placeholder="Status"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- sss, pagibig, philhealth, tin -->
    <v-row>
      <v-col cols="12" md="3">
        <v-text-field
          :value="employeeRecord.sss"
          label="SSS Number"
          outlined
          dense
          placeholder="SSS Number"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="employeeRecord.pagibig"
          label="PagIBIG Number"
          outlined
          dense
          placeholder="PagIBIG Number"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="employeeRecord.philhealth"
          label="Philhealth Number"
          outlined
          dense
          placeholder="Philhealth Number"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="employeeRecord.tin"
          label="Tax ID Number"
          outlined
          dense
          placeholder="Tax ID Number"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- date hired, date regularization, date separation, nature of separation, reason -->
    <v-row>
      <v-col cols="12" md="2">
        <v-text-field
          :value="employeeRecord.date_hired"
          outlined
          dense
          placeholder="Date Hired"
          label="Date Hired"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="employeeRecord.date_regularization"
          outlined
          dense
          placeholder="Date Regularization"
          label="Date Regularization"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="employeeRecord.date_separation"
          outlined
          dense
          placeholder="Date Separation"
          label="Date Separation"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="employeeRecord.nature_of_separation"
          outlined
          dense
          placeholder="Nature of separation"
          label="Nature of separation"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          :value="employeeRecord.reason"
          outlined
          dense
          placeholder="Reason"
          label="Reason"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- tax code, account code, Account Name, bank name, account number -->
    <v-row>
      <v-col cols="12" md="2">
        <v-text-field
          :value="employeeRecord.tax_code"
          outlined
          dense
          placeholder="Tax Code"
          label="Tax Code"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="employeeRecord.account_code"
          outlined
          dense
          placeholder="Account Code"
          label="Account Code"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="employeeRecord.account_name"
          outlined
          dense
          placeholder="Account Name"
          label="Account Name"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field
          :value="employeeRecord.bank.name"
          outlined
          dense
          placeholder="Bank Name"
          label="Bank Name"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="employeeRecord.bank_account"
          outlined
          dense
          placeholder="Account Number"
          label="Account Number"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- pay mode, working days, basic rate, annual wtax, cola, discola -->
    <v-row>
      <v-col cols="12" md="2">
        <v-text-field
          :value="employeeRecord.pay_mode"
          outlined
          dense
          placeholder="Pay Mode"
          label="Pay Mode"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="employeeRecord.working_days"
          outlined
          dense
          placeholder="Working Days"
          label="Working Days"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(employeeRecord.basic_rate)"
          outlined
          dense
          placeholder="Basic Rate"
          label="Basic Rate"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model="annualWtax"
          outlined
          dense
          type="number"
          placeholder="Annual WTax"
          label="Annual WTax"
          :error-messages="formErrorFields.annualWtaxErrorMessage"
          @keydown="formError().remove('annualWtax', formErrorFields)"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(employeeRecord.cola)"
          outlined
          dense
          placeholder="COLA"
          label="COLA"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(employeeRecord.discola)"
          outlined
          dense
          placeholder="DisCOLA"
          label="DisCOLA"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- union dues, transpo allowance, responsibility allowance, training allowance, allowance 2 -->
    <v-row>
      <v-col cols="12" md="2">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(employeeRecord.union_dues)"
          outlined
          dense
          placeholder="Union Dues"
          label="Union Dues"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(employeeRecord.transportation_allowance)"
          outlined
          dense
          placeholder="Transporation Allowance"
          label="Transporation Allowance"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(employeeRecord.resp_allowance)"
          outlined
          dense
          placeholder="Responsibility Allowance"
          label="Responsibility Allowance"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(employeeRecord.training_allowance)"
          outlined
          dense
          placeholder="Training Allowance"
          label="Training Allowance"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          :value="Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(employeeRecord.allowance_2)"
          outlined
          dense
          placeholder="Allowance 2"
          label="Allowance 2"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- security deposit -->
    <v-row>
      <v-subheader>Security Deposit</v-subheader>
    </v-row>
    <v-row class="mt-0 pt-0">
      <v-col cols="12" md="4">
        <v-text-field
          v-model="securityDepositBank"
          outlined
          dense
          placeholder="Bank"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="securityDepositAccountNumber"
          outlined
          dense
          placeholder="Account Number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="securityDepositAccountName"
          outlined
          dense
          placeholder="Account Name"
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- checkboxes -->
    <v-row>
      <v-col cols="12" md="2">
        <v-checkbox
          :value="employeeRecord.union_member === 'Yes'"
          label="Union Member"
          color="primary"
          disabled
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox
          :value="employeeRecord.cash_handler === 'Yes'"
          label="Cash Handler"
          color="primary"
          disabled
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox
          :value="employeeRecord.minimum === 'Yes'"
          label="Minimum"
          color="primary"
          disabled
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox
          v-model="confidential"
          label="Confidential"
          color="primary"
          hide-details
          :error-messages="formErrorFields.confidentialErrorMessage"
          @click="formError().remove('confidential', formErrorFields)"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox
          :value="employeeRecord.autoinout === 'Yes'"
          label="Auto In and Out"
          color="primary"
          disabled
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="2">
        <v-checkbox
          :value="employeeRecord.compressedschedule === 'Yes'"
          label="Compressed Schedule"
          color="primary"
          disabled
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>

    <!-- action -->
    <v-row>
      <v-col cols="12">
        <v-btn
          class="float-right"
          color="primary"
          @click="submit">Update</v-btn>
      </v-col>
    </v-row>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </v-form>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'

export default {
  components: { snackbar },
  props: ['data'],
  setup(props) {
    const employeeRecord = ref(null)
    const securityDepositBank = ref('')
    const securityDepositAccountNumber = ref('')
    const securityDepositAccountName = ref('')
    const confidential = ref(false)
    const annualWtax = ref('')
    const loading = ref(false)
    const formErrorFields = ref({
      annualWtaxErrorMessage: '',
      confidential: '',
    })

    const {
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    watch(() => props.data, val => {
      employeeRecord.value = val.record
      annualWtax.value = val.record.annual_tax
      confidential.value = val.record.confidential === 'Yes'
    })

    const submit = async () => {
      const formData = {
        confidential: confidential.value ? 'Yes' : 'No',
        annualWtax: annualWtax.value,
      }

      const uri = `${process.env.VUE_APP_URI}/api/payroll/employees/${props.data.record.id}`
      await update(uri, loading, formData, formErrorFields)
    }

    return {
      employeeRecord,
      securityDepositBank,
      securityDepositAccountNumber,
      securityDepositAccountName,
      confidential,
      annualWtax,
      submit,
      loading,
      formError,
      formErrorFields,
      responseMessage,
      responseMessageStatus,
    }
  },
}
</script>
