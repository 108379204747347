<template>
  <v-row v-if="employee!==null || routeParameter === undefined">
    <v-col cols="12" md="2">
      <div class="mx-auto pr-1">
        <v-img
          width="180"
          height="90%"
          :src="employee !== null ? (employee.record.image !== null ? imageSource : defaultImage) : defaultImage"
        ></v-img>
      </div>
    </v-col>
    <v-col cols="12" md="10">
      <v-form class="multi-col-validation mt-1">
        <v-row>
          <v-col cols="12" md="3">
            <!-- search employee -->
            <search-employee
              :employeeId="employeeId"
              :employeeNumber="employeeNumber"
              @outputId="getRecord"
              :itemText="'employee_number'"
            ></search-employee>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              :value="employee !== null ? employee.record.previous_employee_number : ''"
              label="Previous Employee Number"
              outlined
              dense
              placeholder="Previous Employee Number"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              :value="employee !== null ? employee.record.bio_id : ''"
              label="Bio ID"
              outlined
              dense
              placeholder="Bio ID"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              :value="employee !== null ? employee.record.origin : ''"
              label="Origin"
              outlined
              dense
              placeholder="Origin"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              :value="employee !== null ? employee.record.firstname.toUpperCase() : ''"
              label="First Name"
              outlined
              dense
              placeholder="First Name"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :value="employee !== null ? employee.record.middlename.toUpperCase() : ''"
              label="Middle Name"
              outlined
              dense
              placeholder="Middle Name"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              :value="employee !== null ? employee.record.lastname.toUpperCase() : ''"
              label="Last Name"
              outlined
              dense
              placeholder="Last Name"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              :value="employee !== null ? employee.record.email.toLowerCase() : ''"
              label="Email"
              outlined
              dense
              placeholder="Email"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import searchEmployee from '@/components/SearchEmployee.vue'
import { useRouter } from '@/utils'
import fnx from '@/functions/fn'
import get from '@/composables/get'

export default {
  components: {
    searchEmployee,
  },

  setup(props, { emit }) {
    const router = useRouter()
    const employeeId = ref(null)
    const employeeNumber = ref('')
    const imageSource = ref('')
    const defaultImage = require('@/assets/images/avatars/not-available.png')
    const employee = ref(null)

    const routeParameter = router.route.value.params.data !== undefined ? fnx.base64_decode(router.route.value.params.data) : undefined

    const getRecord = async val => {
      employeeId.value = val
      await get(`${process.env.VUE_APP_URI}/api/payroll/employees/show/${val}`, employee, null)
      emit('emitData', employee)
      imageSource.value = `${process.env.VUE_APP_URI}/image/${fnx.base64_encode(employee.value.record.image)}`
    }

    if (routeParameter !== undefined) {
      setTimeout(async () => {
        await get(`${process.env.VUE_APP_URI}/api/payroll/employees/show/${routeParameter.id}/${routeParameter.source}`, employee, null)
        emit('emitData', employee)
        employeeId.value = routeParameter.employeeId
        employeeNumber.value = routeParameter.employeeNumber
        imageSource.value = `${process.env.VUE_APP_URI}/image/${fnx.base64_encode(employee.value.record.image)}`
      }, 1000)
    }

    return {
      employeeId,
      employeeNumber,
      getRecord,
      imageSource,
      defaultImage,
      employee,
      routeParameter,
    }
  },
}
</script>
